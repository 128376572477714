<template>
  <div>
    <Header 
      button="Adicionar Produto"
      title="Gerenciamento de Produtos"
      subtitle="Gerencie seus produtos e sua exposição."
      @click="cleanItemFocus(), action = 'Novo', $modal.show('product')"
    >
      <Select v-if="categoriesUsed.length" class="mt-4 mb-2" name="categoria" label="Filtrar" :values="categoriesUsed" v-model="categorieFilter" />
      <button @click="productsOrganize = [...products], $modal.show('organize')" type="button" class="w-full sm:w-auto px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">Reordenar</button>
    </Header>
    <div v-if="products.length" class="max-w-7xl mx-auto mt-3 px-4 sm:px-6 lg:px-8">
      <div class="bg-white shadow overflow-hidden rounded-md">
        <ul class="divide-y divide-gray-200">
          <li v-for="(item, index) in (categorieFilter) ? productsFiltered : products" :key="index">
            <div class="flex items-center px-4 py-4 sm:px-6 hover:bg-gray-50">
              <div class="min-w-0 flex-1 flex items-center">
                <img class="w-12 h-12 rounded" :src="item.image" :alt="item.title" />
                <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                  <div>
                    <p class="font-medium text-sm text-indigo-600 truncate">{{ item.title || '' }}</p>
                    <p class="text-gray-500 text-xs font-medium truncate">{{ item.price | currency }}</p>
                  </div>
                  <div class="hidden md:block">
                    <span class="inline-flex items-center px-2 rounded-full text-sm font-medium bg-blue-100 text-primarydark text-center">{{ item.categorie }}</span>
                    <p class="text-xs text-gray-600 mt -1"><span class="font-semibold">Peso:</span> {{ item.weight ? `${item.weight} Kg` : 'Não definido' }}</p>
                  </div>
                </div>
              </div>
              <!-- Botões -->
              <div class="flex">
                <button @click="toggleVisibility(item)" type="button" class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
                  <svg v-if="item.invisible" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z" clip-rule="evenodd" /><path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" /></svg>
                  <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z" /><path fill-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd" /></svg>
                </button>
                <button @click="getProduct(item), action = 'Editar'" type="button" class="ml-2 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" /></svg>
                </button>
                <button @click="getProduct(item), action = 'Anexar'" type="button" class="ml-2 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" /></svg>
                </button>
                <button @click="itemFocus = item, $modal.show('deleteProduct')" type="button" class="ml-2 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" /></svg>
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <t-modal name="product" class="z-45">
      <p class="text-base font-medium text-gray-800">{{ action }} Produto</p>
      <form v-if="categoriesAll.length" @submit.prevent class="mt-4">
        <div class="grid grid-cols-3 gap-2">
          <div class="col-span-3">
            <Select name="categoriaFocus" label="Categoria" :values="categoriesAll.map(item => item.name)" v-model="itemFocus.categorie" />
          </div>
          <div class="col-span-3">
            <label for="title" class="block text-sm font-medium text-gray-700">Título</label>
            <input v-model="itemFocus.title" type="text" name="title" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
          </div>
          <div class="col-span-3">
            <label for="titleDestaque" class="block text-sm font-medium text-gray-700">Título em Destaque</label>
            <input v-model="itemFocus.titleDestaque" type="text" name="titleDestaque" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
          </div>
          <div class="col-span-1">
            <label for="priceML" class="block text-sm font-medium text-gray-700">Preço ML</label>
            <money v-model="itemFocus.priceML" name="priceML" class="mt-1 py-2 px-3 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border border-gray-300 rounded-md" />
          </div>
          <div class="col-span-1">
            <label for="priceFinal" class="block text-sm font-medium text-gray-700">Preço Final</label>
            <money v-model="itemFocus.priceFinal" name="priceFinal" class="mt-1 py-2 px-3 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border border-gray-300 rounded-md" />
          </div>
          <div class="col-span-1">
            <label for="weight" class="block text-sm font-medium text-gray-700">Peso</label>
            <money v-model="itemFocus.weight" v-bind="{ decimal: ',', thousands: '', suffix: ' Kg', precision: 3 }" name="weight" class="mt-1 py-2 px-3 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border border-gray-300 rounded-md" />
          </div>
          <div class="col-span-3">
            <label for="content" class="block text-sm font-medium text-gray-700">Descrição</label>
            <textarea v-model="itemFocus.description" rows="3" type="text" name="description" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"></textarea>
          </div>
          <div class="col-span-3 mt-1 sm:mt-0">
            <label for="midia" class="block text-sm font-medium text-gray-700 mb-1">Foto de Apresentação</label>
            <div class="max-w-lg bg-gray-50 flex justify-center px-6 pt-5 pb-6 border border-gray-300 rounded-md">
              <div class="space-y-1 text-center">
                <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                  <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <div class="flex text-sm text-gray-600 justify-center">
                  <label for="file-upload" class="relative cursor-pointer rounded-md font-medium text-primarydark hover:text-primary focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary">
                    <span>Envie um arquivo</span>
                    <input id="file-upload" name="file-upload" type="file" accept="image/*" class="sr-only" @change="uploadPhoto">
                  </label>
                </div>
                <p class="text-xs text-gray-500 flex items-center">
                  <svg v-if="file" xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-1" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                  </svg>
                  {{ file || 'PNG ou JPG até 10MB' }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-span-3 relative flex items-start">
            <div class="flex h-6 items-center">
              <input v-model="itemFocus.multiversion" id="multiversion" name="multiversion" type="checkbox" class="h-4 w-4 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary border-gray-300 rounded-md">
            </div>
            <div class="ml-3 text-sm leading-6">
              <label for="comments" class="font-medium text-gray-900">Multi Versões</label>
              <span class="ml-1.5 text-gray-500">(12V / 24V)</span>
            </div>
          </div>
        </div>
        <div class="flex space-x-4">
          <button @click="toggleAvailable(itemFocus)" v-if="action === 'Editar'" type="button" :class="itemFocus.unavailable ? 'bg-red-400 hover:bg-red-600 focus:ring-red-400' : 'bg-green-400 hover:bg-green-600 focus:ring-green-400'" class="mt-4 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2">
            <svg xmlns="http://www.w3.org/2000/svg" class="mr-1.5 h-4 w-4" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 17h12M4 17l3.5-3.5M4 17l3.5 3.5M7 7h13m0 0l-3.5-3.5M20 7l-3.5 3.5"/></svg>
            Produto {{ itemFocus.unavailable ? 'Indisponível' : 'Disponível' }}
          </button>
          <button @click="saveProduct(itemFocus, action)" type="submit" class="mt-4 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
            Salvar
          </button>
        </div>
      </form>
      <div v-else class="flex flex-col justify-center">
        <p class="my-5 text-justify px-2">Cadastre ao menos uma categoria para prosseguir</p>
        <button @click="$router.push('/admin/categorias')" type="button" class="inline-flex items-center mx-2 justify-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-primarydark bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
          Ir
        </button>
      </div>
    </t-modal>
    <t-modal name="attachProduct" class="z-45">
      <p class="text-base font-medium text-gray-800">Anexos</p>
      <form @submit.prevent class="mt-4">
        <div>
          <label for="identify" class="block text-sm font-medium text-gray-700">Identificador</label>
          <div class="mt-1 flex rounded-md shadow-sm">
            <input v-model="attach.identify" type="text" name="identify" id="identify" class="focus:ring-primary focus:border-primary block w-full rounded-none rounded-l-md sm:text-sm border-gray-300" placeholder="Datasheet, Manual...">
            <label for="file-upload" class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
              <svg v-if="file" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
              </svg>
              <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
              </svg>
              <span>{{ file ? `${file.substring(0, 4)}...` : 'Arquivo' }}</span>
              <input id="file-upload" name="file-upload" type="file" class="sr-only" @change="uploadAttachment">
            </label>
          </div>
        </div>
        <button @click="saveAttachment()" type="submit" class="mt-4 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
          Salvar
        </button>
      </form>
      <div v-if="itemFocus.attachments && itemFocus.attachments.length">
        <label for="files" class="mt-4 block text-sm font-medium text-gray-700">Anexos disponíveis</label>
        <dd class="mt-1 text-sm text-gray-900">
          <ul class="border border-gray-200 rounded-md divide-y divide-gray-200">
            <li v-for="(item, index) in itemFocus.attachments" :key="index" class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
              <div class="w-0 flex-1 flex items-center">
                <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clip-rule="evenodd" />
                </svg>
                <span class="ml-2 flex-1 w-0 truncate">
                  {{ item.identify }}
                </span>
              </div>
              <div class="ml-4 flex-shrink-0">
                <a @click="deleteAttachment(item)" class="font-medium text-primary hover:text-secundary">
                  Apagar
                </a>
              </div>
            </li>
          </ul>
        </dd>
      </div>
      <p class="mt-4 pt-1.5 text-base font-medium text-gray-800 border-t">Opcionais</p>
      <label for="options" class="mt-4 mb-1 block text-sm font-medium text-gray-700">Produtos</label>
      <multiselect
        v-model="optionals"
        :options="this.products.map(item => item.title)"
        :multiple="true"
        :close-on-select="true"
        placeholder="Selecione"
      />
      <button @click="saveOptionals()" type="submit" class="mt-4 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
        Salvar
      </button>
    </t-modal>
    <t-modal name="deleteProduct" class="z-45">
      <p class="text-base font-medium text-gray-800">Excluir Produto</p>
      <form @submit.prevent class="text-center">
        <div class="flex justify-center my-8">
          <img class="w-24 h-24 rounded" :src="itemFocus.image" :alt="itemFocus.title" />
        </div>
        <p class="font-medium">Tem certeza que deseja excluir esse produto?</p>
        <p class="text-gray-600 text-sm mb-4">Ele deixará de ser vinculado na página de produtos.</p>
        <button @click="deleteProduct(itemFocus)" type="submit" class="mt-4 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
          Apagar
        </button>
      </form>
    </t-modal> 
    <t-modal name="organize" class="z-45">
      <p class="text-base font-medium text-gray-800">Organizar Produtos</p>
      <p class="text-xs mt-1 mb-4 text-gray-600">Arraste os itens e posicione-os na ordem desejada para exibição.</p>
      <div class="bg-gray-100 border shadow overflow-hidden rounded-md">
        <ul class="divide-y divide-gray-200">
          <Draggable tag="ul" :list="productsOrganize" class="list-group" handle=".handle">
          <li v-for="(prod, i) in productsOrganize" :key="i">
            <div class="flex items-center p-3 hover:bg-gray-50">
              <div class="min-w-0 flex-1 flex items-center">
                <img class="w-6 h-6 rounded" :src="prod.image" />
                <p class="font-medium ml-2 text-sm text-indigo-600 truncate">{{ prod.title }}</p>
              </div>
              <button type="button" class="handle ml-2 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" /></svg>
              </button>
            </div>
          </li>
          </Draggable>
        </ul>
      </div>
      <button @click="organizeProducts()" type="submit" class="mt-4 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">Salvar</button>
    </t-modal>
  </div>
</template>

<script>
import { firebase, listingsColl, productsColl, storageRef } from '@/firebase'

export default {
  components: {
    Draggable: () => import('vuedraggable'),
    Multiselect: () => import('vue-multiselect'),
    Select: () => import('@/components/form/select'),
    Header: () => import('@/components/layout/header'),
  },

  data() {
    return {
      file: '',
      action: '',
      uploadTask: '',
      products: [],
      productsOrganize: [],
      itemFocus: {},
      optionals: [],
      categoriesAll: [],
      categorieFilter: '',
      attach: { identify: '' }, 
    }
  },

  computed: {
    categoriesUsed: function() {
      return this.products.filter((thing, index, self) => self.findIndex(t => t.categorie === thing.categorie) === index).map(item => item.categorie)
    },

    productsFiltered: function() {
      return this.products.filter(item => item.categorie === this.categorieFilter)
    }
  },

  mounted() { this.getProducts(), this.getCategories() },

  methods: {
    async getProducts() {
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      await listingsColl.doc('Products').get()
      .then((result) => { this.products = result.data().available })
      .catch(() => { this.$toast.error('Falha ao obter os produtos, tente novamente') })
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    },

    async getProduct(item) {
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      await productsColl.doc(item.id).get()
      .then((result) => { 
        const index = this.products.findIndex(value => value.id === item.id)
        this.itemFocus = { ...result.data(), invisible: !!this.products[index].invisible, unavailable: !!this.products[index].unavailable }
        this.itemFocus.id = result.id
        if (!this.itemFocus.multiversion) { this.itemFocus.multiversion = false }
        this.optionals = result.data().optionals

        if (this.action === 'Editar') { this.$modal.show('product') } else { this.$modal.show('attachProduct') } 
      })
      .catch(() => { this.$toast.error('Falha ao obter o produto, tente novamente') })
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    },

    async getCategories() {
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      await listingsColl.doc('Categories').get()
      .then((result) => { this.categoriesAll = result.data().available })
      .catch(() => { this.$toast.error('Falha ao obter as categorias, tente novamente') })
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    },

    cleanItemFocus() {
      this.itemFocus = { categorie: '', image: '', priceFinal: '', priceML: '', weight: '', title: '', titleDestaque: '', description: '', multiversion: false }
    },

    async saveProduct(item, action) {
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      if (action === 'Novo') {
        if (!item.categorie || !item.title || !item.titleDestaque || !item.priceML || !item.priceFinal || !item.weight || !item.description || !this.file) {
          this.$store.commit('setLoading', false)
          return this.$toast.error('Todos os campos são obrigatórios, tente novamente')
        }
        await productsColl.add(item)
        .then(async (result) => {
          await listingsColl.doc('Products').update({ available: firebase.firestore.FieldValue.arrayUnion({id: result.id, title: item.title, titleDestaque: item.titleDestaque, price: item.priceFinal, weight: item.weight, image: item.image, categorie: item.categorie, multiversion: item.multiversion || false }) })
          .then(() => { this.$modal.hide('product'), this.$toast.success('Produto adicionado com sucesso'), this.getProducts() })
          .catch(() => { this.$toast.error('Falha ao adicionar produto, tente novamente') })
          
          let categorie = this.categoriesAll.filter(categorie => categorie.name === item.categorie)[0]
      
          await listingsColl.doc('Categories').update({ available: firebase.firestore.FieldValue.arrayRemove(categorie) })
          categorie.products++
          await listingsColl.doc('Categories').update({ available: firebase.firestore.FieldValue.arrayUnion(categorie) })
        }).catch(() => { this.$toast.error('Falha ao adicionar produto, tente novamente') })
      }
      if (action === 'Editar') {
        const index = this.products.findIndex(val => val.id === item.id)
        this.products[index] = { id: item.id, title: item.title || '', titleDestaque: item.titleDestaque || '', price: item.priceFinal, weight: item.weight, image: item.image, categorie: item.categorie, multiversion: item.multiversion || false }
        await listingsColl.doc('Products').update({ available: this.products })
        const id = item.id
        delete item.id; delete item.invisible; delete item.unavailable
        await productsColl.doc(id).update(item)
        .then(() => { this.$modal.hide('product'), this.$toast.success('Produto editado com sucesso'), this.getProducts() })
        .catch(() => { this.$toast.error('Falha ao editar produto, tente novamente') })
      }
      this.file = ''
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    },

    async deleteProduct(item) {
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      await productsColl.doc(item.id).delete()
      .then(async () => {
        await listingsColl.doc('Products').update({ available: firebase.firestore.FieldValue.arrayRemove(item) })
        .then(() => { this.$modal.hide('deleteProduct'), this.$toast.success('Produto excluído com sucesso'), this.getProducts() })
        .catch(() => { this.$toast.error('Falha ao excluir produto, tente novamente') })
  
        let categorie = this.categoriesAll.filter(categorie => categorie.name === item.categorie)[0]
      
        await listingsColl.doc('Categories').update({ available: firebase.firestore.FieldValue.arrayRemove(categorie) })
        categorie.products--
        await listingsColl.doc('Categories').update({ available: firebase.firestore.FieldValue.arrayUnion(categorie) })
      }).catch(() => { this.$toast.error('Falha ao excluir produto, tente novamente') })
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    },

    async saveAttachment() {
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      if (!this.attach.identify || !this.attach.path) {
        this.$store.commit('setLoading', false)
        return this.$toast.error('Todos os campos são obrigatórios, tente novamente')
      }
      await productsColl.doc(this.itemFocus.id).update({ attachments: firebase.firestore.FieldValue.arrayUnion(this.attach) })
      .then(() => { this.$modal.hide('attachProduct'), this.$toast.success('Anexo adicionado com sucesso') })
      .catch(() => { this.$toast.error('Falha ao adicionar anexo, tente novamente') })
      this.file = '', this.attach = { identify: '' }
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    },

    async saveOptionals() {
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      await productsColl.doc(this.itemFocus.id).update({ optionals: this.optionals })
      .then(() => { this.$modal.hide('attachProduct'), this.$toast.success('Opcionais salvos com sucesso') })
      .catch(() => { this.$toast.error('Falha ao salvar, tente novamente') })
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    },

    async deleteAttachment(item) {
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      await productsColl.doc(this.itemFocus.id).update({ attachments: firebase.firestore.FieldValue.arrayRemove(item) })
      .then(() => { this.$modal.hide('attachProduct'), this.$toast.success('Anexo excluído com sucesso') })
      .catch(() => { this.$toast.error('Falha ao excluir anexo, tente novamente') })
      const reference = item.path.substring(item.path.indexOf('productsAttachments'), item.path.indexOf('?alt=')).replace('%2F', '/')
      await storageRef.child(reference).delete().catch(() => {})
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    },

    async uploadPhoto(e) {
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      this.file = e.target.files[0].name
      this.uploadTask = await storageRef.child('productsPictures/' + Date.now()).put(e.target.files[0])
      .then(response => { response.ref.getDownloadURL().then(downloadURL => { this.itemFocus.image = downloadURL }) })
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    },

    async uploadAttachment(e) {
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      this.file = e.target.files[0].name
      this.uploadTask = await storageRef.child(`productsAttachments/${Date.now()}.${(e.target?.files[0]?.name || 'a.txt').split('.')[1]}`).put(e.target.files[0])
      .then(response => { response.ref.getDownloadURL().then(downloadURL => { this.attach.path = downloadURL }) })
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    },

    async organizeProducts() {
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      await listingsColl.doc('Products').update({ available: this.productsOrganize })
      .then(() => { this.$modal.hide('organize'), this.$toast.success('Produtos organizados com sucesso'), this.getProducts() })
      .catch(() => { this.$toast.error('Falha ao organizar produtos, tente novamente') })
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    },

    async toggleVisibility(item) {
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      let index = this.products.findIndex(value => value.id === item.id)
      this.products[index]['invisible'] = this.products[index].invisible ? false : true
      await listingsColl.doc('Products').update({ available: this.products })
      .then(() => { this.$toast.success('Visibilidade editada com sucesso'), this.getProducts() })
      .catch(() => { this.$toast.error('Falha ao editar produto, tente novamente') })
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    },

    async toggleAvailable(item) {
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      let index = this.products.findIndex(value => value.id === item.id)
      this.products[index]['unavailable'] = this.products[index].unavailable ? false : true
      await listingsColl.doc('Products').update({ available: this.products })
      .then(() => { 
        this.itemFocus.unavailable = this.products[index]['unavailable']
        this.$toast.success('Disponibilidade editada com sucesso')
        this.getProducts()
      })
      .catch(() => { this.$toast.error('Falha ao editar produto, tente novamente') })
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    }
  },

  filters: {
    currency(val) {
      var currency = parseFloat(val)
      return currency.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>